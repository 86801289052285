/** @jsx jsx */
import { jsx } from 'theme-ui'

import PageBuilder from '~/componentsV2/pageBuilder'

const Preview = () => {
  return <PageBuilder path="pages" pageId="preview" noIndex={true} />
}

export default Preview
